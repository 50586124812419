<template>
    <div class="main-auth">
        <img class="img-bg" src="~/assets/images/auth-bg.jpeg" />
        <div class="auth-form">
            <h3>Green Moon </br><v-icon>{{ `mdi-account` }}</v-icon></h3>
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            >

                <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                ></v-text-field>

                <v-text-field
                v-model="password"
                :counter="10"
                :label="$t('login.password')"
                type="password"
                required
                ></v-text-field>

                <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || $t('login.do_must_agree')]"
                :label="$t('login.do_you_agree')"
                required
                ></v-checkbox>

                <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
                >{{$t('login.signin')}}</v-btn>

            </v-form>
        </div>
    </div>
</template>

<style lang="scss">
.main-auth{
  text-align:center;
  .img-bg{
    width: 100%;
    position: fixed;
    height: 100%;
    left: 0;
}
.auth-form{
    max-width: 600px;
    width: 90%;
    background-color: #fffffffa !important;
    border-radius: 8px;
    margin: 0 auto;
    min-height: 500px;
    color: #0e4544 !important;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
}
}

</style>
<script>
  export default {
     layout: 'noAuth',
     data: () => ({
        valid: true,
        name: '',
        password: '',
        nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        email: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        select: null,
        items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
        ],
        checkbox: false,
    }),

     methods: {
        validate () {
          if(this.$refs.form.validate()){
            this.$auth.loginWith('laravelJWT', {
              data: {
                email: this.email,
                password: '__password__'
            }
        })
        }
    },
    reset () {
      this.$refs.form.reset()
  },
  resetValidation () {
      this.$refs.form.resetValidation()
  },
},
};
</script>