<template>
    <v-footer id="core-footer" height="82">
        <div class="footer-items">
            <span v-for="link in links" :key="link.name">
                <a :href="link.Link" class="tertiary--text footer-links">
                    {{ link.name }}
                </a>
            </span>
        </div>
        <v-spacer />
        <span class="font-weight-light copyright">
            &copy;
            {{ new Date().getFullYear() }}
            <a href="https://www.creative-tim.com/" target="_blank">Creative Tim</a>
            , made with
            <v-icon color="tertiary" size="17">mdi-heart</v-icon>
            for a better web
            <br />
            &copy; {{ new Date().getFullYear() }}
            <a href="https://vk.com/krupkin.sergey" target="_blank">
                Rekryt
                <v-icon color="tertiary" size="17">mdi-star</v-icon>
            </a>
            , nuxtjs+vuetify+dashboard
        </span>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
        links: [
            { name: 'Home', Link: '/#' },
            { name: 'Creative Tim', Link: 'https://www.creative-tim.com' },
            { name: 'About Us', Link: 'https://creative-tim.com/presentation' },
            { name: 'Blog', Link: 'https://blog.creative-tim.com' },
        ],
    }),
};
</script>

<style>
#core-footer {
    z-index: 0;
}
</style>
